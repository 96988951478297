$fonts: (
    zb: (
        400: (
            name: 'bellfort/BellfortDrawRough',
            weight: 400,
            style: normal
        ),
        500: (
            name: 'bellfort/BellfortDrawHollow-Bold',
            weight: 500,
            style: normal
        ),
        700: (
            name: 'bellfort/BellfortDrawRough-Bold',
            weight: 700,
            style: normal
        ),
        900: (
            name: 'bellfort/BellfortDrawDark-Bold',
            weight: 900,
            style: normal
        )
    )
);

@each $font-family-key, $font-family-value in $fonts {
    @each $family-item-key, $family-item-value in $font-family-value {
        @font-face {
            $get-font-name: map-get($family-item-value, name);

            font-family: #{$font-family-key};
            src: url(#{$fonts-folder}#{$get-font-name}.woff2) format('woff2'),
            url(#{$fonts-folder}#{$get-font-name}.woff) format('woff'),
            url(#{$fonts-folder}#{$get-font-name}.ttf) format('truetype');
            font-weight: map-get($family-item-value, weight);
            font-style: map-get($family-item-value, style);
        }
    }
}

// Family
$font-family-regular: 'zb', "Arial", sans-serif;

// Weight
$font-weight-thin: 100;
$font-weight-extra-light: 200;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-demi: 600;
$font-weight-bold: 700;
$font-weight-extra-bold: 800;
$font-weight-black: 900;
$font-style-italic: italic;

// Spacing
$letter-spacing-extra-small: .025em;
$letter-spacing-small: .05em;
$letter-spacing-base: .1em;
$letter-spacing-medium: .15em;

// Sizes
$font-size-tiny-px: 14px;
$font-size-extra-small-px: 16px;
$font-size-small-px: 18px;
$font-size-base-px: 20px;
$font-size-medium-px: 24px;
$font-size-large-px: 34px;
$font-size-medium-large-px: 54px;
$font-size-extra-large-px: 64px;
$font-size-huge-px: 100px;
$font-size-giant-px: 120px;
$font-size-giga-px: 140px;

$font-size-tiny: calculateRem($font-size-base-px, $font-size-tiny-px);
$font-size-extra-small: calculateRem($font-size-base-px, $font-size-extra-small-px);
$font-size-small: calculateRem($font-size-base-px, $font-size-small-px);
$font-size-base: calculateRem($font-size-base-px, $font-size-base-px);
$font-size-medium: calculateRem($font-size-base-px, $font-size-medium-px);
$font-size-large: calculateRem($font-size-base-px, $font-size-large-px);
$font-size-medium-large: calculateRem($font-size-base-px, $font-size-medium-large-px);
$font-size-extra-large: calculateRem($font-size-base-px, $font-size-extra-large-px);
$font-size-huge: calculateRem($font-size-base-px, $font-size-huge-px);
$font-size-giant: calculateRem($font-size-base-px, $font-size-giant-px);
$font-size-giga: calculateRem($font-size-base-px, $font-size-giga-px);

// Line Height
$line-height-extra-small: 1.08em;
$line-height-small: 1.14em;
$line-height-base: 1.36em;
$line-height-medium: 1.5em;
$line-height-huge: 1.714em;

// Color
$font-color-base: color(black);

.font
{
    &--base
    {
        font-size: $font-size-base;
        line-height: $line-height-base;
    }

    &--small
    {
        font-size: $font-size-small;
        line-height: $line-height-base;
    }

    &--medium
    {
        font-size: $font-size-medium;
        line-height: $line-height-medium;
    }

    &--large
    {
        font-size: $font-size-large;
        line-height: $line-height-medium;
    }

    &--regular
    {
        font-weight: $font-weight-regular;
    }

    &--bold
    {
        font-weight: $font-weight-bold;
    }
}

html, body
{
    font-size: $font-size-base-px;
    line-height: $line-height-base;
}

@media only screen and (max-width: $screen-md)
{
    html, body
    {
    }
}

@media only screen and (max-width: $screen-sm)
{
    html, body
    {
        font-size: $font-size-small-px;
    }
}

