$button-height-base: 50px;

:root {
    --button-bg-color: #{color(white)};
    --button-color: #{color(grey, 900)};
}

.button
{
    $button: &;

    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: auto;
    height: $button-height-base;
    line-height: $button-height-base;
    padding: 0 spacing(md);
    font-size: $font-size-base;
    font-weight: $font-weight-bold;
    text-transform: none;
    letter-spacing: initial;
    color: var(--button-color);
    position: relative;
    transition: all 200ms ease-in-out;
    appearance: none;
    -webkit-appearance: none;
    outline: none;
    cursor: pointer;

    &--split {
        #{$button}__placeholder {
            opacity: 0;
        }

        #{$button}__text {
            opacity: 0;
            position: absolute;
            z-index: 2;
            color: var(--button-bg-color);
            text-shadow: 0 0 20px var(--color-accent-primary), 0 0 20px  var(--color-accent-primary), 0 0 20px  var(--color-accent-primary), 0 0 20px  var(--color-accent-primary);
            transition: all 300ms cubic-bezier(1.000, -0.055, 0.025, 1.040);
            transition-timing-function: cubic-bezier(1.000, -0.055, 0.025, 1.040);
            transition-delay:0.35s;
        }

        #{$button}__anim {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            position: absolute;
            color: inherit;

            span {
                display: inline-flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: calc(#{$button-height-base} / 2);
                padding-top: calc((#{$button-height-base} / 2) - #{$border-width-base});
                position: absolute;
                top: 0;
                overflow: hidden;
                transform-origin: center;
                border: $border-width-base solid var(--button-color);
                border-bottom: none;
                color: currentColor;
                background-color: rgba(color(white), 0.75);
                backdrop-filter: blur(10px);

                transition: all 300ms cubic-bezier(1.000, -0.055, 0.025, 1.040);
                transition-timing-function: cubic-bezier(1.000, -0.055, 0.025, 1.040);
                transition-delay:0.35s;

                &:last-child {
                    top: auto;
                    bottom: 0;
                    padding-top: 0;
                    padding-bottom: calc((#{$button-height-base} / 2) - #{$border-width-base});
                    border-top: none;
                    border-bottom: $border-width-base solid var(--button-color);
                }
            }
        }

        #{$button}__arrow {
            $arrow: &;

            display: flex;
            align-items: flex-end;
            width: 0;
            height: $border-width-base;
            position: absolute;
            left: -20px;
            color: currentColor;
            transform: rotate(0);
            transition: all 1000ms cubic-bezier(0.890, -0.170, 0.140, 1.225);
            transition-timing-function: cubic-bezier(0.890, -0.170, 0.140, 1.225);

            &:before,
            &:after
            {
                content: '';
                display: block;
                position: absolute;
                z-index: 0;
            }

            &:before
            {
                width: 10px;
                height: 10px;
                margin-bottom: -4px;
                border-bottom: $border-width-base solid currentColor;
                border-right: $border-width-base solid currentColor;
                transform: rotate(-45deg);
                bottom: 0;
                right: 0;
                opacity: 0;
                transition: all 1000ms cubic-bezier(0.890, -0.170, 0.140, 1.225);
                transition-timing-function: cubic-bezier(0.890, -0.170, 0.140, 1.225);
            }

            &:after
            {
                height: inherit;
                width: 100%;
                background: currentColor;
                top: 0;
                left: 0;
            }
        }

        &:hover {
            #{$button} {
                &__arrow {
                    width: 130%;

                    &:before {
                        opacity: 1;
                    }
                }
                &__text {
                    opacity: 1;
                    transform: scale(1.2);
                }
                &__anim {
                    span {
                        margin: -16px -5px 0 0;
                        transform: rotate(3deg);

                        &:last-child {
                            margin: 0 0 -18px -7px;
                            transform: rotate(-1.7deg);
                        }
                    }
                }
            }
        }

        &--vert {
            height: 44px;
            padding: 0 20px;
            font-weight: $font-weight-regular;

            #{$button} {
                &__anim {
                    span {
                        width: 50%;
                        height: 100%;
                        left: 0;
                        padding: 0;
                        padding-left: calc(50% - #{$border-width-base});
                        border: $border-width-base solid var(--button-color);
                        border-right: none;
                        border-radius: 50px 0 0 50px;
                        background: transparent;

                        &:last-child {
                            left: auto;
                            right: 0;
                            padding: 0;
                            padding-right: calc(50% - #{$border-width-base});
                            border: $border-width-base solid var(--button-color);
                            border-left: none;
                            border-radius: 0 50px 50px 0;
                        }
                    }
                }

                &__text {
                    font-weight: $font-weight-medium;
                    color: var(--primary-color);
                }

                &__arrow {
                    left: 50%;
                    top: -100%;
                    transform: rotate(90deg);
                    transform-origin: left;
                }
            }
            &:hover {
                #{$button} {
                    &__arrow {
                        left: 50%;
                        width: 100%;
                    }

                    &__anim {
                        span {
                            margin: -4px -5px 0 -36px;

                            &:last-child {
                                margin: 0 -27px -7px 0;
                            }
                        }
                    }
                }
            }
        }
    }

    &--full {
        width: 100%;
    }

    &--simple {
        background-color: var(--button-bg-color);

        #{$button}__placeholder {
            opacity: 1;
        }

        &:before {
            display: none;
        }

        &:hover {
            background-color: var(--secondary-color);
            color: var(--secondary-color-negative);
        }
    }

    &--bordered {
        background: transparent;
        color: color(white);
        border: $border-width-base solid var(--secondary-color);

        &:hover {
            border-color: var(--secondary-color);
        }
    }

    &--primary {
        background: var(--secondary-color);
        color: var(--secondary-color-negative);


        &#{$button}--split {
            background: transparent;

            #{$button}__anim {
                span {
                    background: var(--secondary-color);
                }
            }

            &:hover {
                #{$button}__text {
                    text-shadow: 0 0 20px color(black), 0 0 20px  color(black), 0 0 20px  color(black), 0 0 20px  color(black);
                }
            }
        }
    }
}
