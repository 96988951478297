.hero
{
    --hero-height-base: 670px;
    --hero-frame-border-width: 70px;

    display: flex;
    max-width: container(max);
    height: auto;
    padding-top: var(--frame-width);
    isolation: isolate;

    &__content
    {
        height: var(--hero-height-base);
        margin-inline: var(--frame-width);
        padding-block: var(--frame-width);
        position: relative;
    }

    &__caption
    {
        display: flex;
        flex-direction: column;
        position: absolute;
        gap: spacing(xs);
        left: calc(var(--hero-frame-border-width) + #{spacing(md)});
        top: calc(var(--hero-frame-border-width) + #{spacing(md)});;
        z-index: 20;

        .caption {
            color: var(--color-accent-primary);
        }

        &--right {
            left: auto;
            right: calc(var(--hero-frame-border-width) + #{spacing(md)});
            text-align: right;
        }
    }

    &__title-group {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 10;
        top: 0;
        left: 0;
        padding-block-start: spacing(lg);
        background-color: transparent;
    }

    &__title
    {
        position: relative;
        z-index: 10;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    &__part {
        text-transform: uppercase;
        letter-spacing: 1.5em;
        color: inherit;
        padding-top: spacing(md);
        font-weight: $font-weight-bold;
    }

    &__description {
        text-align: center;
        font-weight: $font-weight-bold;
    }

    &__background
    {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        max-width: 100vw;
        max-height: 100vh;
        position: absolute;
        top: 0;
        left: 0;

        img
        {
            position: relative;
            z-index: 5;
            min-width: 1000px;
            width: 100vw;
            height: auto;
            max-width: none;
            margin-inline: auto;
        }
    }

    &__border
    {
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        color: color(white);

        &-top,
        &-right,
        &-bottom,
        &-left
        {
            background-color: currentColor;
            position: absolute;
        }

        &-top
        {
            width: 100%;
            height: var(--hero-frame-border-width);
            top: 0;
            left: 0;
            z-index: 6;
        }

        &-right
        {
            width: var(--hero-frame-border-width);
            height: 100%;
            right: 0;
            top: 0;
            z-index: 6;
        }

        &-bottom
        {
            width: 100%;
            height: var(--hero-frame-border-width);
            bottom: 0;
            right: 0;
        }

        &-left
        {
            width: var(--hero-frame-border-width);
            height: 100%;
            left: 0;
            bottom: 0;
        }
    }

    &__arrow
    {
        $arrow: &;

        --hero-arrow-height-base: 160px;

        display: flex;
        align-items: flex-end;
        width: 100px;
        height: var(--hero-arrow-height-base);
        position: absolute;
        z-index: 11;
        left: 70%;
        bottom: -40px;
        color: var(--color-accent-primary);

        &:before,
        &:after
        {
            content: '';
            display: block;
            position: absolute;
            left: 50%;
            z-index: 0;
        }

        &:before
        {
            width: 10px;
            height: 10px;
            margin-left: -4px;
            border-bottom: $border-width-base solid currentColor;
            border-right: $border-width-base solid currentColor;
            transform: rotate(45deg);
            bottom: 0;
            animation: heroArrowHead 2s infinite;
        }

        &:after
        {
            width: $border-width-base;
            height: var(--hero-arrow-height-base);
            background: currentColor;
            top: 0;
            animation: heroArrowLine 2s infinite;
        }

        &:hover
        {
            &:before,
            &:after
            {
                animation: none;
            }
        }
    }
}

@media only screen and (max-width: $screen-md)
{
    .hero
    {
        min-height: 0;

        &__content {
            margin-left: 0;
            margin-right: 0;
            height: 50vh;
        }

        &__title
        {
            font-size: $font-size-extra-large;
        }
    }
}

@media only screen and (max-width: $screen-sm)
{
    .hero
    {
        --hero-frame-border-width: 32px;

        padding-top: var(--frame-width);

        &__content {
            display: flex;
            flex-direction: column;
            min-height: 400px;
            height: auto;
            padding: 0;
        }

        &__caption {
            top: calc(var(--hero-frame-border-width) + #{spacing(base)});
            left: calc(var(--hero-frame-border-width) + #{spacing(base)});

            &--right {
                left: auto;
                right: calc(var(--hero-frame-border-width) + #{spacing(base)});
            }
        }

        &__title
        {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            font-size: $font-size-huge;
            line-height: $font-size-huge;
            overflow-wrap: anywhere;
            //padding-inline: var(--hero-frame-border-width);
            padding-inline: 0;

            &-group {
                min-height: inherit;
                height: 100%;
                padding: 132px 0 0 0;
                position: relative;
                text-align: center;
            }
        }

        &__description {
            text-align: left;
            padding: calc(var(--hero-frame-border-width) * 2);
            padding-top: 0;
            bottom: 0;
            left: 0;
        }

        &__arrow
        {
            display: none;
        }

        &__background
        {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 1px;
            height: 1px;
            position: absolute;
            left: 50%;
            top: 50%;

            img {
                position: absolute;
                width: 200vw;
                min-width: 1000px;
                //width: auto;
                height: auto;
                margin: 0;
            }
        }

        > .section__container
        {
            padding: var(--frame-width);
            padding-top: 0;
        }
    }
}

@keyframes heroArrowHead
{
    0%
    {
        opacity: 0;
    }
    25%
    {
        opacity: 0;
    }
    40%
    {
        opacity: 1;
    }
    100%
    {
        opacity: 1;
    }
}

@keyframes heroArrowLine
{
    0%
    {
        height: 0;
        transform: translateY(-40%);
    }
    25%
    {
        height: var(--hero-arrow-height-base);
        transform: translateY(0);
    }
    100%
    {
        height: var(--hero-arrow-height-base);
        transform: translateY(0);
    }
}
