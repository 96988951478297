$header-height: 100px;
$header-height-sm: 64px;
$header-bg: transparent;

.header
{
    $root: &;

    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    min-height: $header-height;
    height: $header-height;
    background-color: $header-bg;
    transition: $transition-base;
    padding-inline: 64px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 21;
    color: color(white);

    .logo {
        width: var(--logo-width);
        height: var(--logo-height);
    }

    a {
        display: flex;
        font-weight: $font-weight-bold;
        font-size: $font-size-medium;
        transition: all 300ms ease-in-out;
        position: relative;

        &:before {
            content: '';
            display: block;
            width: 120%;
            height: 120%;
            background-color: var(--primary-color);
            position: absolute;
            z-index: -1;
            left: -10%;
            transform: scaleX(0);
            transform-origin: left;
            transition: all 200ms ease-in-out;
        }

        &:hover {
            &:before {
                transform: scaleX(1);
            }
        }
    }
}

@media only screen and (max-width: $screen-md)
{
    .header
    {
        height: $header-height-sm;
    }
}

@media only screen and (max-width: $screen-sm)
{
    .header
    {
        min-height: $header-height-sm;
        height: $header-height-sm;
        padding-inline: 20px;
    }
}

@media only screen and (min-width: $screen-lg)
{
    .header
    {
    }
}
