.embed
{
    display: flex;
    justify-content: center;
    position: relative;
    overflow: hidden;

    &__youtube
    {
        padding-top: 56.27021276595745%;
        width: 80%;
    }
}
