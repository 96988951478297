.site-frame {
    $root: &;

    display: flex;
    align-items: flex-start;
    flex-direction: column;
    width: var(--frame-width);
    height: 100%;
    min-height: var(--frame-width);
    padding: var(--frame-width) 0;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 10;
    color: color(white);
    mix-blend-mode: difference;

    &--centered {
        align-items: center;
    }

    &--top {
        width: 100%;
        height: var(--frame-width);
        transform: none;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        padding: 0;
        gap: spacing(base);
        z-index: 21;
    }

    &--right {
        left: auto;
        right: 0;

        & #{$root}__text {
            transform: rotate(90deg) translate3d(var(--frame-width), 0, 0);
            transform-origin: left top 0;
        }
    }

    &__text {
        display: flex;
        align-items: center;
        height: var(--frame-width);
        position: relative;
        left: var(--frame-width);
        line-height: 1;
        transform: rotate(270deg) translate3d(-100%, 0, 0);
        transform-origin: left bottom 0;
        white-space: nowrap;
    }

    &__block {
        align-items: center;
        justify-content: center;
        width: var(--frame-width);
        height: var(--frame-width);
        min-height: 0;
        padding: 0;
        z-index: 11;
        transition: all 600ms ease-in-out;
        user-select: none;

        &--right {
            left: calc(100% - var(--frame-width));
        }

        &--absolute {
            position: absolute;
        }

        &--hidden {
            margin-left: calc(-1 * var(--frame-width));
        }
    }

    &.navigation-trigger {
        cursor: pointer;

        .menu-text {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            width: 100%;
            height: 100%;
            position: absolute;
            left: -62px;
            text-align: right;
            transform: translateY(0);
            opacity: 1;
            transition: all 300ms;
            will-change: transform;
            user-select: none;

            &--hidden {
                opacity: 0;
                transform: translateY(-58px);
            }
        }
        .menu-icon {
            display: block;
            width: 25px;
            height: 25px;

            &:before,
            &:after {
                content: '';
                display: block;
                width: inherit;
                height: $border-width-base;
                background: currentColor;
                position: absolute;
                z-index: 1;
                top: 50%;
                margin-top: -1.875px;
                transform: translateY(-3.75px) translateZ(0);
                transition:
                    background-color 1s cubic-bezier(0.23, 1, 0.32, 1),
                    transform 1s cubic-bezier(0.23, 1, 0.32, 1),
                    width 1s ease-in-out;
                transition-delay: 0s, 0s, 0.2s;
            }

            &:after {
                transform: translateY(5.75px) translateZ(0);
            }
        }

        &:after {
            content: '';
            display: block;
            width: 80%;
            height: 80%;
            position: absolute;
            right: -100%;
            top: 10%;
            background-color: color(white);
            transform: rotate(76deg);
            border-radius: 50%;
            transition: $transition-fast;
        }

        &.active {
            &:after {
                right: 10%;
                margin-right: -$border-width-base / 2;
                transform: rotate(0);
            }

            .menu-icon {
                &:before,
                &:after {
                    background-color: color(black);
                }
            }
        }

        &.active {
            .menu-icon {
                &:after {
                    transform: rotate(45deg) translateZ(0);
                }

                &:before {
                    transform: rotate(-45deg) translateZ(0);
                }
            }
        }
    }

    .logo {
        width: var(--logo-width);
        height: var(--logo-height);
    }
}

html[overlay-visible="true"] {
    .site-frame {
        &--navigation {
            .site-frame__block {
                margin-left: calc(-1 * var(--frame-width));
            }
        }
    }
}

@media only screen and (min-width: $screen-md + 1) {
    .site-frame {
        &.navigation-trigger {
            &:active {
                &:after {
                    width: 90%;
                    height: 90%;
                    top: 5%;
                    right: 5% !important;
                    opacity: .8;
                }
            }

            &:hover {
                &:after {
                    right: 10%;
                    margin-right: -$border-width-base / 2;
                    transform: rotate(0);
                }

                .menu-icon {
                    &:before,
                    &:after {
                        background-color: color(black);
                    }
                    &:after {
                        width: 20%;
                    }
                }
            }

            &.active {
                .menu-icon {
                    &:after {
                        width: inherit;
                        transition:
                            background-color 1s cubic-bezier(0.23, 1, 0.32, 1),
                            transform 1s cubic-bezier(0.23, 1, 0.32, 1),
                            width 0s ease-in-out;
                        transition-delay: 0s, 0s, 0s;
                    }
                }
                &:hover {
                    .menu-icon {
                        &:before {
                            transform: rotate(-135deg) translateZ(0)
                        }
                        &:after {
                            transform: rotate(-45deg) translateZ(0)
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: $screen-sm) {
    html {
        &[page="home"] {
            .site-frame {
                &__logo {
                    //background: rgba(color(white), .5);
                    //color: color(black);
                }
            }
        }
    }
    .site-frame {
        &.navigation-trigger {
            z-index: 12;

            .menu-icon {
                width: 20px;
                height: 20px;
            }

            .menu-text {
                left: calc(-1 * (var(--frame-width) - #{spacing(sm)}));
            }

            &:after {
                width: 90%;
                height: 90%;
                top: 5%;
            }

            &.active {
                &:after {
                    right: 5%;
                    margin-right: 0;
                    margin-top: $border-width-base / 2;
                }
            }
        }

        &--top {
            gap: spacing(tn);
            justify-content: flex-start;
            padding-left: calc(var(--frame-width) + #{spacing(base)});
        }

        &__logo {
            width: 100%;
            align-items: flex-start;
            pointer-events: none;
            padding-inline: spacing(base);

            > a {
                pointer-events: auto;
            }
        }
    }
    html {
        &[overlay-visible="true"] {
            .site-frame {
                &__logo {
                    color: color(white);
                }
            }
        }
    }
}

html {
    &[page="about"] {
        .site-frame {
            mix-blend-mode: initial;
        }
    }
}
