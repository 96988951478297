$slide-content-width: 80vw;
$slide-content-height: 100vh;

html {
    &[page="home"] {
        overflow-y: auto;
        overscroll-behavior-y: contain;
        scroll-snap-type: y mandatory;

        &[loading="true"],
        &[overlay-visible="true"] {
            overflow-y: hidden;
            overscroll-behavior-y: none;
            scroll-snap-type: none;
        }
    }
}

.home
{
    &__section {
        $section: &;

        height: $slide-content-height;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        flex-direction: column;
        scroll-snap-align: center;
        perspective: 500px;
        user-select: none;
        overflow: hidden;

        &-content {
            display: flex;
            flex-direction: column;
            width: 100%;
            max-width: 100%;
            height: $slide-content-height;
            padding-inline: var(--frame-width);
            align-items: center;
            justify-content: center;
            overflow: hidden;
        }

        &-title {
            position: relative;
            text-align: center;
            z-index: 1;
            margin-bottom: 460px;
            mix-blend-mode: hard-light;
        }

        &-list {
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 3;
            width: 100%;
            height: auto;
            gap: 40px;
            transform: translateY(20px);
            //height: 100vh;

            li {
                display: inline-flex;
                width: auto;
                height: auto;
                position: relative;
                //transform: rotate(90deg);
            }

            .store-link {
                color: var(--primary-color-negative);
                transition: all 300ms ease-in-out;

                &:hover {
                    transform: scale(1.1) rotate(-1deg);
                    color: var(--primary-color);
                }

                .icon {
                    width: inherit;
                    height: inherit;
                    fill: currentColor;
                }

                &--spotify-full {
                    width: 182px;
                    height: 55px;
                }

                &--youtube-full {
                    width: 234px;
                    height: 34px;
                }

                &--apple-full {
                    width: 140px;
                    height: 34px;
                }
            }
        }

        &-background {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 1px;
            height: 1px;
            position: absolute;
            z-index: 1;

            img {
                width: 581px;
                height: auto;
                max-width: none;
                max-height: none;
                position: absolute;
                backface-visibility: hidden;
                animation: 1.5s cubic-bezier(.25, 1, .30, 1) circle-out-center both;
                will-change: clip-path;
            }

            &--graphics {
                z-index: -1;

                img {
                    width: 739px;
                }
            }
        }

        &-button-container {
            position: relative;
            z-index: 3;
        }

        &--active {
            &#{$section}--wrapless {
            }

            #{$section} {
                &-background {
                    img {
                        animation: 2.5s cubic-bezier(.25, 1, .30, 1) circle-in-center both;
                    }
                }
            }
        }

        &--footer {
            color: color(white);
            height: auto;
            padding-block: 64px;
        }

        .follow-bg {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            width: 100%;
            height: 100%;
            overflow: hidden;
            color: color(white);
            font-size: $font-size-giant;
            transform: rotate(2deg);

            span {
                white-space: nowrap;
                line-height: 100%;
                margin-top: -0.1em;
                font-weight: $font-weight-medium;

                a {
                    font-weight: $font-weight-black;
                    color: var(--primary-color);
                    position: relative;
                    z-index: 1;
                    transition: all 200ms ease-in-out;

                    &:before {
                        content: '';
                        display: block;
                        width: 120%;
                        height: 120%;
                        background-color: var(--primary-color);
                        position: absolute;
                        z-index: -1;
                        left: -10%;
                        transform: scaleX(0);
                        transform-origin: left;
                        transition: all 200ms ease-in-out;
                    }

                    &:hover {
                        color: var(--primary-color-neg);
                        &:before {
                            transform: scaleX(1);
                        }
                    }
                }
            }
        }
    }
}

html {
    &[page="home"] {
        &[section="nkor"] {
            --background-color: #{color(blue)};
            //--color-accent-negative: #{color(white)};
        }
        &[section="kiadhatatlan"] {
            //--color-accent-primary: #{color(brand, wrapless)};
            //--color-accent-negative: #{color(white)};
        }
        &[section="akkezdetphiai"] {
            --background-color: #2569C8;
            --primary-color: #{color(white)};
        }
    }
}

@keyframes circle-in-center {
    from {
        clip-path: circle(0%);
    }
    to {
        clip-path: circle(125%);
    }
}

@keyframes circle-out-center {
    from {
        clip-path: circle(125%);
    }
    to {
        clip-path: circle(0%);
    }
}

@media only screen and (max-width: $screen-sm)
{
    html {
        .home {
            padding-top: 0;

            &__section {
                min-height: 100vh;
                min-height: -webkit-fill-available;

                &--follow {
                    min-height: 0;
                    height: auto;
                    padding-block: 20px;
                }

                &-content {
                    min-height: 100vh;
                    min-height: -webkit-fill-available;
                    padding-inline: spacing(sm);
                }

                &-title {
                    overflow-wrap: anywhere;
                    font-size: $font-size-huge;
                    margin-bottom: 25vh;
                }

                .follow-bg {
                    font-size: $font-size-extra-large;
                }

                &-list {
                    flex-direction: column;
                    gap: 20px;
                    transform: scale(.8) translateY(15vh);
                }

                &-background {
                    img {
                        max-width: 100vw;
                    }
                }
            }
        }
    }
}
