$colors: (
    black: (
        base: black
    ),
    white: (
        base: white
    ),
    grey: (
        base: #67676B,
        900: #1B1B1F,
        800: #363638,
        700: #464649,
        600: #57575A,
        500: #67676B,
        400: #7F7F83,
        300: #98989A,
        200: #B0B0B2,
        100: #C8C8CA,
        50: #D4D4D6,
        25: #F6F6F9
    ),
    brand: (
        linkedin: #2F65BD,
        spotify: #1ED760
    ),
    accent: (
        primary: #F71735,
        secondary: yellow,
    ),
    yellow: (base: yellow),
    orange: (base: orange),
    red: (base: #F71735),
    pink: (base: pink),
    purple: (base: purple),
    blue: (base: #0A4D50),
    green: (base: green)
);

@function color($color, $tone: 'base')
{
    @return map-get(map_get($colors, $color), $tone);
}

@each $color-key, $color-value in $colors {
    @each $tone-key, $tone-value in $color-value {
        @if $tone-key == base {
            .color#{'--' + $color-key} {
                color: $tone-value !important;
            }

            .fill#{'--' + $color-key} {
                background-color: $tone-value !important;
            }
        } @else {
            .color#{'--' + $color-key + '-' + $tone-key} {
                color: $tone-value !important;
            }

            .fill#{'--' + $color-key + '-' + $tone-key} {
                background-color: $tone-value !important;
            }
        }
    }
}

:root {
    @each $color-key, $color-value in $colors {
        @each $tone-key, $tone-value in $color-value {
            @if $tone-key == base {
                --color#{'-' + $color-key}: #{$tone-value};
            } @else {
                --color#{'-' + $color-key + '-' + $tone-key}: #{$tone-value};
            }
        }
    }
}

:root {
    --color-on-body: #{color(white)};
    --background-color: #{color(grey, 900)};
    --primary-color: #{color(red)};
    --primary-color-negative: #{color(white)};
    --secondary-color: #{color(red)};
    --secondary-color-negative: #{color(white)};
    --color-accent-primary: #{color(red)};
    --color-accent-negative: #{color(white)};
    --highlight-bg-color: var(--color-accent-primary);
    --highlight-color: var(--color-accent-negative);
}

html {
    &[page="home"] {
        --background-color: #{color(black)};
    }
}
