html {
    &[page="home"]{
        scroll-behavior: smooth;
    }
}

.animated-letter {
    position: relative !important;
}

.reveal
{
    position: relative;
    overflow: hidden;
    max-width: 100%;
    box-shadow: none;
    transition: box-shadow 200ms ease-in-out;

    &__mask
    {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
        display: inline-block;
        background-color: var(--background-color);
        will-change: transform;
    }
}

@media only screen and (max-width: $screen-sm) {
    html {
        &[page="home"]{
            scroll-behavior: smooth;
        }
    }
}
