small
{
    font-size: $font-size-tiny;
    line-height: 1;
}

strong
{
    font-weight: $font-weight-bold;
}

.outline {
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke: 1.42px currentColor;
}

p, li
{
    //margin-bottom: spacing(base);
    font-weight: $font-weight-regular;
    line-height: $line-height-medium;
}
