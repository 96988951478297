html {
    height: -webkit-fill-available;
    transition: -webkit-scrollbar 400ms ease-in-out;

    ::selection {
        background: var(--highlight-bg-color);
        color: var(--highlight-color);
    }

    ::-webkit-scrollbar {
        height: 5px;
        width: 5px;
        background: var(--background-color);
        transition: all 500ms ease-in-out;
    }

    ::-webkit-scrollbar-thumb {
        background: var(--color-accent-primary);
        -webkit-border-radius: 1ex;
        transition: all 500ms ease-in-out;
    }

    ::-webkit-scrollbar-corner {
        background: var(--background-color);
        transition: background 500ms ease-in-out;
    }

    &[loading="true"],
    &[overlay-visible="true"] {
        overflow: hidden;

        body {
            pointer-events: none;

            .site-frame {
                pointer-events: auto;
            }
        }

        ::-webkit-scrollbar {
            display: none;
        }
    }
}
