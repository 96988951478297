.device
{
    $root: &;

    display: inline-block;
    width: auto;
    height: 100%;
    position: relative;

    &__image
    {
        max-width: 100%;
        display: inline-block;
        vertical-align: middle;
    }

    &__screen
    {
        position: absolute;
        overflow: hidden;
    }

    &--notebook
    {
        left: 8%;

        #{$root}__screen
        {
            width: 64.6%;
            height: 40.2%;
            left: 12.2%;
            top: 3.1%;
        }
    }

    &--mobile
    {
        min-width: 214px;
        border-radius: 32px;

        #{$root}__screen
        {
            width: 87.4%;
            height: 94%;
            left: 6.4%;
            top: 3.1%;
            border-radius: 20px;
        }

        &--landscape
        {
            min-width: 432px;

            #{$root}__screen
            {
                width: 93.6%;
                height: 88.7%;
                left: 3.3%;
                top: 6.1%;

                img
                {
                    width: auto;
                    height: 100%;
                }
            }
        }
    }

    &--tablet
    {
        min-width: 520px;
        border-radius: 22px;

        #{$root}__screen
        {
            width: 81.4%;
            height: 77.5%;
            left: 9.2%;
            top: 10.5%;
        }
    }

    &-composition
    {
        $composition: &;

        display: flex;
        flex-direction: row;
        width: 100%;

        &__column
        {
            display: flex;
            flex-basis: auto;
            flex-direction: column;
            max-width: none;
            flex-shrink: 1;
        }

        &__section
        {
            display: flex;
            align-items: flex-end;

            &--end
            {
                justify-content: flex-end;
            }

            &--offset
            {
                &--top
                {
                    margin-top: spacing(hg);
                }
            }
        }

        &__device
        {
            padding: spacing(base);
        }

        &--2-columns
        {
            #{$composition}__column
            {
                &:first-child
                {
                    margin-left: auto;
                }

                &:last-child
                {
                    margin-right: auto;
                }
            }
        }
    }
}

@media only screen and (max-width: $screen-md)
{
    .device
    {
        $root: &;

        min-width: 0 !important;

        &-composition
        {
            $composition: &;

            flex-direction: column;

            &__column
            {
                margin-left: auto;
                margin-right: auto;
            }

            &__device
            {
                padding: 0;
                margin-bottom: spacing(lg);
            }

            &__section
            {
                flex-direction: column;
                align-items: center;

                &--end
                {
                    justify-content: center;
                }

                &--offset
                {
                    &--top
                    {
                        margin-top: 0;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: $screen-sm)
{
    .device
    {
        $root: &;

        &-composition
        {
            $composition: &;
        }
    }
}
