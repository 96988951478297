.hidden
{
    display: none !important;
}

.overflow {
    &-hidden {
        overflow: hidden;
    }
}

.pointer-events-none
{
    pointer-events: none;
}

.white-space-nowrap
{
    white-space: nowrap;
}

.lang
{
    display: none;
}

.box-shadow {
    &--heavy {
        box-shadow: $box-shadow-heavy;
    }
}

.clearfix:before, .clearfix:after
{
    content: " ";
    display: table
}

.clearfix:after
{
    clear: both
}

.position
{
    &--relative
    {
        position: relative;
    }
}

.z-index
{
    position: relative;

    &--behind {
        @extend .z-index;
        z-index: -1;
    }

    &--top
    {
        @extend .z-index;
        z-index: 999;
    }

    &--1 {
        @extend .z-index;
        z-index: 1;
    }

    &--2 {
        @extend .z-index;
        z-index: 2;
    }

    &--3 {
        @extend .z-index;
        z-index: 3;
    }
}

.height {
    &--full {
        height: 100%;
    }
}

@media only screen and (max-width: $screen-lg) {
    .hide {
        &--on-lg {
            display: none !important;
        }
    }
}

@media only screen and (max-width: $screen-md)
{
    .hide
    {
        &--on-md
        {
            display: none !important;
        }
    }

    .show
    {
        &--on-md
        {
            display: block !important;
        }
    }

    &.reverse
    {
        &--md
        {
            flex-direction: column-reverse !important;
        }
    }
}

@media only screen and (max-width: $screen-sm)
{
    .hide
    {
        &--on-sm
        {
            display: none !important;
        }
    }

    .show
    {
        &--on-sm
        {
            display: flex !important;
        }
    }

    &.reverse
    {
        &--sm
        {
            flex-direction: column-reverse !important;
        }
    }
}

@media only screen and (min-width: $screen-md)
{
    .hide
    {
        &--on-md
        {
            display: none !important;
        }
    }
}
