.navigation
{
    $navigation: &;

    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: spacing(tn);
    padding-inline: var(--frame-width);
    position: relative;
    z-index: 1;

    &__item
    {
        position: relative;
    }

    &__image
    {
        width: 100vw;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
    }

    &__link
    {
        font-size: $font-size-extra-large;
        font-weight: $font-weight-black;
        line-height: $font-size-extra-large;
        text-transform: uppercase;
        -webkit-text-stroke: $border-width-base var(--secondary-color);
        color: transparent;
        position: relative;
        transition: -webkit-text-stroke 300ms ease-in-out;

        &-sub-title {
            display: block;
            text-transform: none;
            font-weight: $font-weight-demi;
            font-size: $font-size-tiny;
            line-height: 1.25em;
            -webkit-text-stroke: initial;
            color: var(--secondary-color);
            margin-bottom: 0;
            transition: color 300ms ease-in-out;
        }

        &.active
        {
            -webkit-text-stroke: initial;
            color: color(white);
            -webkit-text-fill-color: currentColor;
            cursor: initial;
            pointer-events: none;

            #{$navigation} {
                &__link {
                    &-sub-title {
                        color: inherit;
                    }
                }
            }
        }

        &:hover
        {
            -webkit-text-stroke: $border-width-base color(white);

            #{$navigation} {
                &__link-sub-title {
                    color: color(white);
                }
            }
        }
    }
}

@media only screen and (max-width: $screen-sm)
{
    .navigation {
        gap: 0;

        &__item {
            padding-inline: spacing(md);
        }

        &__link {
            font-size: $font-size-medium-large;
        }
    }
}
