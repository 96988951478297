.page-progress
{
    width: 0;
    height: 4px;
    background: var(--secondary-color);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 5;
    transition: width 500ms;
}
